<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Welcome to Fresh Sales! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please sign-in to your account and start the adventure
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- Mobile Number -->
              <b-form-group
                :label="$t('info.mobileNumber')"
                label-for="mobile-number"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('info.mobileNumber')"
                  vid="mobile-number"
                  rules="required|digits:9"
                >
                  <b-input-group
                    :prepend="`+${mobilePrefix}`"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="mobile-number"
                      v-model="phone"
                      :state="errors.length > 0 ? false:null"
                      name="mobile-number"
                      placeholder="5xxxxxxxx"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                  <b-link :to="{name:'auth-forgot-password'}">
                    <small>Forgot Pin?</small>
                  </b-link>
                </div>

                <b-row class="justify-content-between flex-nowrap">
                  <b-col
                    v-for="(key, i) in pinFields"
                    :key="i"
                    sm="2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="password"
                      rules="required|digits:1"
                    >
                      <b-form-input
                        :ref="`input${i}`"
                        v-model="key.value"
                        type="password"
                        :data-length="key.length"
                        :data-index="i"
                        placeholder="_"
                        class="text-center"
                        @input="setPin(i, key.value)"
                        @keyup.delete="unsetPin(i, key.value)"
                      />
                      <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      phone: null,
      pin: '',
      mobilePrefix: '966',
      pinFields: [
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
        { value: '' },
      ],
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },

  methods: {
    setPin(currentInput, value) {
      this.pin = this.pinFields.map(e => e.value).join('')
      try {
        if (!value) {
          return
        }

        this.$refs[`input${parseInt(currentInput + 1, 10)}`][0].focus()
      } catch (error) {
        console.error(error)
      }
    },

    unsetPin(currentInput, value) {
      this.pin = this.pinFields.map(e => e.value).join('')
      try {
        if (value.toString().length >= 1) {
          return
        }

        this.$refs[`input${parseInt(currentInput - 1, 10)}`][0].focus()
      } catch (error) {
        console.error(error)
      }
    },

    async login() {
      try {
        const validate = this.$refs.loginForm.validate()
        if (!validate) {
          return
        }
        const response = await store.dispatch('app-authentication/login', { phone: this.mobilePrefix + this.phone, pin: this.pin })

        if (response?.data?.status) {
          // const ability = [{
          //   action: 'manage',
          //   subject: 'all',
          // }]

          this.$ability.update(response.data.data[0].abilities)
          this.$router.replace(getHomeRouteForLoggedInUser('admin'))

          // Call System Codes
          const { callStatus, callSStatus } = useSystemCodes()
          callStatus()
          callSStatus()
        }
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
